import * as React from "react";
import Hero from "../components/elements/Hero";
import copy from '../copy/404';
import { H2 } from "../styles/shared/typography";
import * as s from '../styles/pages/404';
import PageSeo from '../components/elements/Seo/PageSeo';

// markup
const NotFoundPage = () => {
  return (
    <>
    <PageSeo pageTitle={ copy.pageSeoTitle } pageDescription={ copy.pageDescription } pageKeywords={ copy.pageKeywords }/>
      <Hero title={copy.title} />
      <s.NotFoundPageSection narrow>
        <H2>{copy.copy}</H2>
      </s.NotFoundPageSection>
    </>
  )
}

export default NotFoundPage;
